const getters = {
  token: state => state.user.token,
  baseRoute: state => state.permission.routes,
  userInfo: state => state.user.accountInfo,
  menuList: state => state.permission.menuList,
  loading: state => state.user.loading,
  permissionList: state => state.permission.permissionList,
  registerTime: state => state.other.registerTime,
  sendTime: state => state.other.sendTime,
  alreadyTime: state => state.other.alreadyTime,
  shipmentTime:state=>state.other.shipmentTime,
  wageMonth:state=>state.other.wageMonth,
  finishTime:state=>state.other.finishTime,
  mode:state=>state.other.mode,
  role:state=>state.permission.role
};
export default getters;
